import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // make sure you add this for firestore
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './index'
import rootSaga from '../Sagas'

const nextRootReducer = require('.').default

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

// Don't remove the commented history variable below. It is used for dell pre-packaging.
// export const history = createHashHistory();

export default () => {
  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware, routerMiddleware(history)]

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware))

  // Do we have preloaded state available? Great, save it.
  const initialState = { firebase: { authError: null } }

  // Create the store
  const store = createStore(
    // connectRouter(history)(rootReducer),
    createRootReducer(history),
    // rootReducer,
    initialState,
    composedEnhancers,
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./index', () => {
        store.replaceReducer(nextRootReducer)
      })
    }
  }

  // kick off root saga
  sagaMiddleware.run(rootSaga)

  return {
    store,
    history,
  }
}
